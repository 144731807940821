import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
  paymentListData: {
    data: null,
    isLoading: false,
    isError: false,
  },
};

// Payment Listing Api
export const paymentListingApi = ({ page, limit, startDate, endDate }) =>
  async (dispatch) => {
    try {
      dispatch(
        paymentState([{ key: "paymentListData.isLoading", value: true }])
      );
      console.log("Fetching data for dates:", startDate, endDate);

      let response;
      if (startDate && endDate) {
        response = await axiosApi.get(
          `admin/payment/list?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
        );
      } else {
        response = await axiosApi.get(
          `admin/payment/list?page=${page}&limit=${limit}`
        );
      }

      if (
        response?.data?.data?.results &&
        Array.isArray(response.data.data.results) &&
        response.data.data.results.length > 0
      ) {
        console.log("Non-empty results found:", response.data.data.results);
        dispatch(
          paymentState([
            { key: "paymentListData.isError", value: false },
            { key: "paymentListData.isLoading", value: false },
            { key: "paymentListData.data", value: response?.data?.data },
          ])
        );
      } else {
        console.log("Empty results. Clearing data.");
        dispatch(
          paymentState([
            { key: "paymentListData.isError", value: false },
            { key: "paymentListData.isLoading", value: false },
            { key: "paymentListData.data", value: [] },
          ])
        );
      }

      return response;
    } catch (error) {
      dispatch(
        paymentState([{ key: "paymentListData.isLoading", value: false }])
      );
      console.error("Error fetching data:", error);
      toast.error(error.response?.data?.message || "An error occurred.");
    }
  };

const paymentSlice = createSlice({
  name: "payment",
  initialState: initialStates,
  reducers: {
    paymentState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { paymentState } = paymentSlice.actions;

const { reducer } = paymentSlice;

export default reducer;

import { ChakravyuhLogo } from "../../../assets/images";

const SpinnerComponent = () => {
  return (
    <>
      {/* <div
        className="fallback-spinner app-loader d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
        <img src={ChakravyuhLogo} height="50px" />
        <div className="loading">
          <div className="effect-1 effects" />
          <div className="effect-2 effects" />
          <div className="effect-3 effects" />
        </div>
      </div> */}
    </>
  );
};

export default SpinnerComponent;
